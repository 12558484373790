<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-3">Compensation</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <div class="container px-3">
        <template>
          <div class="py-2 my-5">
            <div class="flex justify-start items-center gap-5">
              <h2 class="font-bold text-lg leading-5 text-darkPurple">
                Detailed Report
              </h2>
              <Card class="px-5 py-5" v-if="review.year">
                <div class="border-l border-r border-romanSilver px-5 flex justify-start items-center gap-2">
                  <Card class="flex p-2">
                    <Icon icon-name="icon-financial" size="s" />
                  </Card>
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="text-xs text-jet uppercase font-semibold">Financial Year</p>
                    <p class="font-bold text-lg text-blueCrayola">
                      {{ $DATEFORMAT(new Date(review.year), "MMMM dd,yyyy") }}
                    </p>
                  </div>
                </div>
              </Card>
              <Card class="px-5 py-5 w-60 min-w-0">
                <div class="border-l border-r border-romanSilver px-5 flex justify-start items-center gap-2">
                  <Card class="flex p-2">
                    <Icon icon-name="icon-review-timeline" size="s" />
                  </Card>
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="text-xs text-jet uppercase font-semibold">Review Cycle</p>
                    <p class="font-bold text-lg text-mediumSeaGreen">
                      {{ reviews.cycle ? reviews.cycle.cycleName : 'N/A' }}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
            <Card class="px-5 pb-6 my-3">
              <CardFooter
                reloadcard
                @reload="onGetQuery"
                @actionModal="() => {}"
                @sortType="onGetQuery({ sort: $event })"
                @searchResult="onGetQuery({ search: $event })"
              >
                <template v-slot:leftItems>
                  <div class="flex items-center gap-5">
                    <div class="flex gap-2">
                      <template>
                        <Button
                          label="View Timeline"
                          variant="secondary button-class btn-border"
                          @onClick="$refs.view_timelines.toggle(review)"
                        />
                        <ReviewTimelines ref="view_timelines" />
                      </template>
                      <Button
                        label="Submit"
                        style="width:120px"
                        variant="primary button-class btn-bg"
                        :disabled="!reviews.length"
                        @onClick="onSubmitDetailedReport"
                      />
                    </div>
                    <div class="flex justify-center items-center rounded-md shadow p-3 cursor-pointer">
                      <Icon size="xs" icon-name="icon-printer" />
                    </div>
                  </div>
                </template>
              </CardFooter>
            </Card>
          </div>
        </template>
        <template>
          <template v-if="reviews.length">
            <div>
              <Table
                :headers="headers"
                :items="reviews"
                :has-number="true"
                :has-checkbox="true"
                class="w-full"
                :loading="isSubmitting"
                :pagination-list="paginationList"
                @rowSelected="selectedItems = $event"
                page-sync
                @page="onGetQuery({ page: $event })"
                @itemsPerPage="onGetQuery({ perPage: $event })"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.employee" class="font-normal text-sm leading-5 tracking-widest">
                    <div class="flex flex-col justify-start items-start">
                      <span class="text-darkPurple">
                        {{ item.data.employee.fname }}  {{ item.data.employee.lname }}
                      </span>
                      <span class="text-romanSilver">
                        {{ item.data.employee.designation }}
                      </span>
                    </div>
                  </div>
                  <div v-if="item.currentGrossPay" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.currentGrossPay) }}
                      </span>
                  </div>
                  <div v-if="item.performanceScore" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple" v-if="item.data.performanceScore">
                      {{ item.data.performanceScore.toFixed(2) }}
                    </span>
                  </div>
                  <div v-if="item.change" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple" v-if="item.data.change">
                      {{ item.data.change }}%
                    </span>
                  </div>
                  <div v-if="item.newGrossPay" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple">
                      {{ convertToCurrency(item.data.newGrossPay) }}
                    </span>
                  </div>
                  <div v-if="item.currentCompaRatio" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-razzmataz" v-if="item.data.currentCompaRatio < 80">Below Range</span>
                    <span class="text-aztecJade" v-if="item.data.currentCompaRatio > 120">Above Range</span>
                    <span class="text-ceruleanBlue" v-if="(item.data.currentCompaRatio >= 80 && item.data.currentCompaRatio <= 120)">Within Range</span>
                  </div>
                  <div v-if="item.recommendation" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple capitalize">
                      {{ item.data.recommendation ? item.data.recommendation.adjustTo : 'Not Applicable' }}
                    </span>
                  </div>
                  <div v-if="item.userId">
                    <Menu left @click.stop="">
                      <template v-slot:title>
                        <Icon icon-name="more_icon" size="xs" />
                      </template>
                      <template>
                        <div class="flex flex-col w-52 h-18 p-2 justify-start items-start">
                          <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                            <div @click="onToggleAdjustmentProposal(item.data)">
                              <div class="flex justify-start items-center p-2 gap-2">
                                <Icon icon-name="file_todo" class-name="text-carrotOrange" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">
                                  Adjustment Proposal
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                            <div @click="onToggleReviewProposal(item.data)">
                              <div class="flex justify-start items-center p-2 gap-2">
                                <Icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">
                                  Review Proposal
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                            <div @click="onToggleProposalApproval(item.data)">
                              <div class="flex justify-start items-center p-2 gap-2">
                                <Icon icon-name="icon-check-square" class-name="text-mediumSeaGreen" size="xs" />
                                <span class="text-darkPurple text-sm leading-5 font-normal">
                                  Approval Status
                                </span>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </template>
                    </Menu>
                  </div>
                </template>
              </Table>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full">
              <Icon size="" class-name="w-80 h-80" icon-name="comreview_illustration" />
              <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                Detailed Report not available for this Review. You should start adding employees to the list.
              </p>
            </div>
          </template>
          <template>
            <AdjustmentProposals ref="adjustment_proposal" />
            <ReviewProposal ref="review_proposal" />
            <ApprovalStatus ref="approval_status" mode="ess" />
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import * as _ from "lodash"
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Loader from "@scelloo/cloudenly-ui/src/components/loader"
  import Table from "@scelloo/cloudenly-ui/src/components/table"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import Menu from "@scelloo/cloudenly-ui/src/components/Menu"
  import CardFooter from "@/components/CardFooter"
  import Icon from "@/components/Icon"
  import ApprovalStatus from "@/components/ApprovalStatus"

  export default {
    components: {
      Breadcrumb,
      BackButton,
      Loader,
      Table,
      Menu,
      Card,
      Icon,
      Button,
      CardFooter,
      ApprovalStatus,
      ReviewTimelines: () => import('../Modals/ReviewTimelines'),
      ReviewProposal: () => import('../Modals/ReviewProposal'),
      AdjustmentProposals: () => import('../Modals/AdjustmentProposals')
    },
    data(){
      return {
        review: {},
        isLoading: true,
        isSubmitting: false,
        reviews: [],
        timelines: [],
        selectedItems: [],
        queryParams: {},
        breadcrumbs: [
          {
            disabled: false,
            text: "Compensation",
            href: "compensation",
            id: "Compensation"
          },
          {
            disabled: false,
            text: "Compensation Review",
            href: "CompensationReview",
            id: "CompensationReview"
          },
          {
            disabled: false,
            text: "Detailed Report",
            href: "CompensationReviewDetailedReport",
            id: "CompensationReviewDetailedReport"
          }
        ],
        headers: [
          { title: "employee name", value: "employee" },
          { title: "current gross pay", value: "currentGrossPay" },
          { title: "Performance score", value: "performanceScore" },
          { title: "change (%)", value: "change" },
          { title: "new gross pay", value: "newGrossPay" },
          { title: "Salary Status", value: "currentCompaRatio" },
          { title: "Recommendation", value: "recommendation" },
          { title: "", value: "userId", image: true },
        ],
        paginationList: {
          page: 1,
          lastPage: 1,
          total: 1,
          from: 1,
          to: 1,
        },
      }
    },
    methods: {
      async onGetQuery(queryParams){
        if(queryParams !== undefined){
          this.queryParams = {...this.queryParams, ...queryParams}
          this.getManagerReviewDirectReports({
            queryParams: this.queryParams,
            reviewId: this.$route.params.id,
            authUserId: this.$AuthUser.id
          })
        } else {
          this.queryParams = {}
          this.getManagerReviewDirectReports({
            queryParams: this.queryParams,
            reviewId: this.$route.params.id,
            authUserId: this.$AuthUser.id
          })
        }
      },
      onToggleAdjustmentProposal(payload){
        if(payload.paygrade){
          this.$refs.adjustment_proposal.toggle({
            userId: payload.userId, paygradeId: payload.paygrade.id
          })
        } else {
          const message = "No paygrade is set for this employee!"
          this.$toasted.info(message, { duration: 3000 })
        }
      },
      onToggleReviewProposal(payload){
        if(payload.paygrade){
          this.$refs.review_proposal.toggle({
            ...payload, paygradeId: payload.paygrade.id,
          })
        } else {
          const message = "There are no recommendations for this proposal"
          this.$toasted.info(message, { duration: 3000 })
        }
      },
      onToggleProposalApproval(payload){
        if(payload.recommendation){
          this.$refs.approval_status.toggle(payload.recommendation.reviewId)
        } else {
          const message = "There are no proposals for this employee"
          this.$toasted.info(message, { duration: 3000 })
        }
      },
      onSubmitDetailedReport(){
        if(_.isEmpty(this.selectedItems)){
          const message = 'Please select at least one employee to continue!'
          return this.$toasted.info(message,{ duration: 3000 })
        }
        const selectedItems = this.selectedItems.map(({ adjustment }) => ({
          adjustmentId: adjustment.adjustmentId, userId: adjustment.employeeId
        }))

        const payload = {
          orgId: this.$orgId,
          employees: selectedItems,
          reviewId: this.$route.params.id,
        }

        this.isSubmitting = true
        return this.$_postCompensationReviewForApproval(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.onGetQuery()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.onGetQuery()
        })
      },
      async findCompensationReviewById(reviewId){
        await this.$_findCompensationReviewById(reviewId)
        .then(({ data }) => { this.review = data.data })
      },
      async getManagerReviewDirectReports(payload){
        this.isSubmitting = true
        await this.$_getManagerReviewDirectReports(payload).then(({ data }) => {
          this.reviews = data.data?.map((item) => ({
            ...item, ...item.employee, ...item.adjustment
          }))
          this.isSubmitting = false
        })
      },
      async getReviewTimelines(reviewId){
        await this.$_getReviewTimelines(reviewId).then(({ data }) => {
          this.timelines = data.data
        })
      },
    },
    async created(){
      this.isLoading = true
      await this.findCompensationReviewById(this.$route.params.id)
      await this.getReviewTimelines(this.$route.params.id)
      await this.getManagerReviewDirectReports({
        queryParams: this.queryParams,
        reviewId: this.$route.params.id,
        authUserId: this.$AuthUser.id
      })
      this.isLoading = false
    }
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 40px !important;
 }

.btn-bg {
    background-color: var(--dynamic-Background) !important;
}

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
